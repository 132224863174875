import { createReducer } from "@reduxjs/toolkit";
import { setAuthUser, setUserHouseholdId } from '../actions/auth';
import { IUser } from "@qwealth/qdata";

export interface AuthState {
  user: IUser | null;
  authUserHouseholdId: string | null;
}

const defaultState: AuthState = {
  user: null,
  authUserHouseholdId: null
};

export default createReducer(defaultState, (builder) => {
  builder.addCase(setAuthUser, (state, action) =>
    ({...state, user: action.payload}));

  builder.addCase(setUserHouseholdId, (state, action) =>
    ({...state, authUserHouseholdId: action.payload}));
});
