import React, { useState } from 'react';
import { Box } from '@qwealth/qcore';
import { DemographicForm, DemographicInfoConfirmation } from '@qwealth/kyc';
import { UpdateButton } from '../common';
import EditDemographic from './EditDemographic';

interface Props {
  demographicInfo: DemographicForm;
  updateFormValues: (values: any) => void;
}

const DemographicInformation: React.FC<Props> = ({ demographicInfo, updateFormValues }) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const toggleModal = () => setShowEditModal(!showEditModal);
  return (
    <Box display="flex" flexDirection="column">
      <DemographicInfoConfirmation demographicInfo={demographicInfo} />
      <EditDemographic
        updateFormValues={updateFormValues}
        initialValues={demographicInfo}
        shouldShowModal={showEditModal}
        onClose={toggleModal}
      />
      <UpdateButton color="green" onClick={toggleModal}>
        Update
      </UpdateButton>
    </Box>
  );
};

export default DemographicInformation;
