import { Box } from '@qwealth/qcore';

export default function Avatar({ initials, ...rest }) {
  return (
    <Box
      borderRadius="round"
      height="40px"
      width="40px"
      bg="primary"
      fontSize="18px"
      fontWeight="bold"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {initials}
    </Box>
  );
}
