import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { Amplify, I18n } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { httpClientIntegration } from '@sentry/integrations';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from "@qwealth/qcore";
import { QContext } from '@qwealth/qdata';

import App from './App';
import store from './data/store';

import './index.css';
import { errorHandler } from './services/axiosService';

const {
  REACT_APP_VERSION,
  REACT_APP_ENV,
  REACT_APP_QWEALTH_AUTH_REGION,
  REACT_APP_QWEALTH_AUTH_CLIENT_ID,
  REACT_APP_QWEALTH_AUTH_USER_POOL_ID,
  REACT_APP_QWEALTH_IDENTITY_POOL_ID,
  REACT_APP_QWEALTH_S3_BUCKET,
} = process.env;

console.log(`Current app version is: ${REACT_APP_VERSION}`);

QContext.setErrorHandler(errorHandler);

Sentry.init({
  dsn: 'https://cb804504dfd44fb7a980f7689f718f54@o1168654.ingest.sentry.io/6264262',
  integrations: [new BrowserTracing(), httpClientIntegration()],
  tracesSampleRate: 1.0,
  environment: REACT_APP_ENV,
});

Amplify.configure({
  Auth: {
    storage: window.sessionStorage,
    identityPoolId: REACT_APP_QWEALTH_IDENTITY_POOL_ID,
    region: REACT_APP_QWEALTH_AUTH_REGION,
    userPoolId: REACT_APP_QWEALTH_AUTH_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_QWEALTH_AUTH_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_QWEALTH_S3_BUCKET,
      region: REACT_APP_QWEALTH_AUTH_REGION,
    },
  },
});

I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'LOGIN', // Button label
  'Forgot your password?': 'Forgot password?',
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
      />
    </Helmet>
    <Router>
      <Provider store={store}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);
