import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button } from '@qwealth/qcore';
import { selectUser } from '@qwealth/qdata';

import { SplashContainer, StyledBox } from 'components/common/SplashStyling';

const Splash: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const firstName = useSelector(selectUser)?.firstName ?? '';

  return (
    <SplashContainer>
      <Box
        className="title"
        as="div"
        fontSize="headerTitle"
        color="primaryLight.1"
        textAlign="center"
      >
        Welcome, <b>{firstName}</b>
      </Box>
      <Box as="div" fontSize="large" mt="large">
        Every year, we ask our clients to review their information we have on file in order to
        ensure the information is up-to-date,
        <br />
        or to let us know if there are any changes to be made. This lets us ensure that while we are
        investing for them, it is with
        <br /> the latest information at our fingertips.
      </Box>
      <Box as="div" color="primaryLight.1" fontWeight="bold" fontSize="sectionTitle" mt="largest">
        Our Review Process
      </Box>
      <Box as="div" fontSize="large" mt="none">
        The Annual Review is divided into two sections:
      </Box>

      <Box
        className="box-wrapper"
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        marginTop="largest"
      >
        <StyledBox style={{ backgroundColor: '#93315d' }}>
          <div className="number">1</div>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box as="div" color="white" fontSize="headerTitle">
              KYC Review
            </Box>
            <Box as="div" fontSize="large" color="white">
              Validating your basic KYC information and making sure everything is up-to-date.
            </Box>
          </Box>
        </StyledBox>
        <StyledBox style={{ backgroundColor: '#5e1442' }}>
          <div className="number">2</div>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box as="div" color="white" fontSize="headerTitle">
              Questionnaire
            </Box>
            <Box as="div" fontSize="large" color="white">
              A short questionnaire about your
              <br />
              current situation.
            </Box>
          </Box>
        </StyledBox>
      </Box>

      <Box as="div" fontSize="large" mt="largest" mb="large">
        To move to the next section please click the below
      </Box>

      <Button
        width="default"
        color="primary"
        onClick={() => {
          navigate({
            pathname: `${pathname}/kyc-review`
          });
        }}
      >
        Let's Begin!
      </Button>
    </SplashContainer>
  );
};

export default Splash;
