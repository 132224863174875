import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, styled, themeGet } from '@qwealth/qcore';

import ExternalApps from './ExternalApps';

const StyledSideNav = styled(Box)`
  background-color: #171021;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  transition: transform 0.35s ease-in;
  transform: translateX(-150%);
  z-index: 999;
  width: 80%;

  &.show {
    transform: translateX(0);
  }

  @media only screen and (min-width: 1024px) {
    width: 400px;
  }
`;

const StyledLink = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  padding: 1rem 1rem 1rem 3rem;
  color: ${themeGet('colors.white')};
  border: none;
  transition: all 0.25s ease-in;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100%;
  position: relative;
  text-align: left;
  text-transform: uppercase;

  &:after {
    content: '';
    transition: all 0.25s ease-in-out;
    opacity: 0;
    background: ${themeGet('colors.primaryLight.1')};
    width: 5px;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: #ffffff0d;
  }

  &:hover:after {
    opacity: 1;
  }
`;

const StyledSubLink = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  padding: 1rem 1rem 1rem 5rem;
  color: ${themeGet('colors.white')};
  border: none;
  transition: all 0.25s ease-in;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100%;
  position: relative;
  text-align: left;
  text-transform: uppercase;

  &:after {
    content: '';
    transition: all 0.25s ease-in-out;
    opacity: 0;
    background: ${themeGet('colors.primaryLight.1')};
    width: 5px;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: #ffffff0d;
  }

  &:hover:after {
    opacity: 1;
  }
`;

export default function SideNav({ isVisible, onToggle }) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState('');

  const navigateHandler = (pathname) => {
    setSelected('');
    onToggle(false);
    navigate({ pathname });
  };

  return (
    <StyledSideNav className={isVisible ? 'show' : undefined} height="100vh" pt="largest">
      <Box
        px="large"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb="largest"
        style={{ position: 'relative' }}
        onClick={() => onToggle(!isVisible)}
      >
        <Box style={{ position: 'absolute', left: '20px' }}>
          <img alt="close" src="/images/menu-close.png" />
        </Box>

        <img alt="logo" src="/images/QConnect.png" width="40" />
      </Box>
      <Box flex="1">
        <StyledLink onClick={() => navigateHandler('/')}>HOME</StyledLink>
        <StyledLink onClick={() => navigateHandler('/networth')}>NET WORTH</StyledLink>
        <StyledLink onClick={() => setSelected('qlife')}>YOUR QLIFE</StyledLink>
        {selected === 'qlife' && (
          <Box>
            <StyledSubLink onClick={() => navigateHandler('/values')}>Personal Values</StyledSubLink>
            <StyledSubLink onClick={() => navigateHandler('/functions')}>Function of Assets</StyledSubLink>
            <StyledSubLink onClick={() => navigateHandler('/baselineplan')}>Baseline Plan</StyledSubLink>
            <StyledSubLink onClick={() => navigateHandler('/flowReport')}>Inflow/Outflow Report</StyledSubLink>
            <StyledSubLink onClick={() => navigateHandler('/qscore')}>QScore</StyledSubLink>
            <StyledSubLink onClick={() => navigateHandler('/projectedNetworthReport')}>Projected Networth Report</StyledSubLink>
          </Box>
        )}
        <StyledLink onClick={() => navigateHandler('/communications')}>YOUR COMMUNICATIONS</StyledLink>
        <StyledLink onClick={() => navigateHandler('/vault')}>YOUR VAULT</StyledLink>
      </Box>
      <Box p="large">
        <ExternalApps />
      </Box>
    </StyledSideNav>
  );
}
