import React, { FC, ReactElement } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Modal } from 'react-bootstrap';
import { Box, Button } from '@qwealth/qcore';
import { AddressesInternalForm, addressesValidator, AddressFormData } from '@qwealth/kyc';

interface Props {
  shouldShowModal: boolean;
  onClose: () => void;
  initialValues: AddressFormData;
  updateFormValues: (values: any) => Promise<void>;
}

const EditAddresses: FC<Props> = ({
  shouldShowModal,
  onClose,
  initialValues,
  updateFormValues
}): ReactElement => {
  const submitHandler = async (values) => {
    await updateFormValues(values);
    onClose();
  };
  return (
    <Form
      validate={(values) => addressesValidator(values, true).resultClean}
      onSubmit={submitHandler}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={({
        handleSubmit,
        dirty,
        form: {
          mutators: { push }
        }
      }) => (
        <Modal onHide={onClose} show={shouldShowModal} size="xl">
          <Modal.Header closeButton>
            <Box textStyle="sectionTitle">Edit Addresses Information</Box>
          </Modal.Header>
          <Modal.Body>
            <AddressesInternalForm
              editing
              isDirty={dirty}
              push={push}
              showIsBillingAddress
              showIsCurrentAddress
              showSearchBox
            />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={handleSubmit}>
              Update
            </Button>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    />
  );
};

export default EditAddresses;
