import React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Modal } from 'react-bootstrap';
import { Box, Button } from '@qwealth/qcore';
import { FinancialForm, FinancialInfoForm, financialValidator } from '@qwealth/kyc';

interface Props {
  shouldShowModal: boolean;
  onClose: () => void;
  initialValues: FinancialForm;
  updateFormValues: (values: any) => Promise<void>;
}

const EditFinancial: React.FC<Props> = ({
  shouldShowModal,
  onClose,
  initialValues,
  updateFormValues
}) => {
  const submitHandler = async (values) => {
    await updateFormValues(values);
    onClose();
  };
  return (
    <Form
      validate={(values) => financialValidator(values).resultClean}
      onSubmit={submitHandler}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit }) => (
        <Modal onHide={onClose} show={shouldShowModal} size="xl">
          <Modal.Header closeButton>
            <Box textStyle="sectionTitle">Edit Financial Information</Box>
          </Modal.Header>
          <Modal.Body>
            <FinancialInfoForm editing context="Contact" />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={handleSubmit}>
              Update
            </Button>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    />
  );
};

export default EditFinancial;
