import axios from 'axios';
import * as Sentry from '@sentry/react';
import { QWealthAuthorizationInterceptor } from '@qwealth/qdata';

export const { REACT_APP_QWEALTH_API, REACT_APP_QWEALTH_QC_API } = process.env;

export const configureAxios = () => {
  // interceptor to set the authorization only on QW API requests
  axios.interceptors.request.use(QWealthAuthorizationInterceptor);

  return Promise.resolve(true);
};

export const responseMapper = (response) => {
  const { data } = response.data;
  return data;
};

export const errorHandler = (dispatch, errorText = 'API Error') => async (error) => {
  Sentry.addBreadcrumb({
    message: errorText,
    level: 'debug',
    data: error
  });
  Sentry.captureException(error);
  console.error(error);

  // TODO: remove this after we have better error handling
  await axios.post(`${REACT_APP_QWEALTH_API}/logger/qconnect/`, {
    error, errorText
  }).catch(() => console.error('failed to capture error on QW side'));

  return Promise.reject(error);
};
