import axios from 'axios';
import { errorHandler, REACT_APP_QWEALTH_QC_API } from '../../services/axiosService';

export const PUT_TIMELINE = 'PUT_TIMELINE';

export const loadTimeline = (householdId) => {
  const URL = new window.URL(
    `${REACT_APP_QWEALTH_QC_API}/qscore/household/${householdId}/timeline`
  );
  
  URL.searchParams.set('types', ['BASELINE', 'SIMULATION']);

  return (dispatch) => {
    axios
      .get(URL.toString())
      .then((response) => response.data)
      .then((timeline) => dispatch({ type: PUT_TIMELINE, timeline }))
      .catch(errorHandler(dispatch));
  };
};
