import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from "./Header";
import { useDispatchOnMount } from "@qwealth/qcore";
import { getPersons, initializeHouseholdAccounts, loadTeam, loadWorkshop, selectIACode } from "@qwealth/qdata";
import { getHousehold } from "../data/actions/householdAction";
import { shallowEqual, useSelector } from "react-redux";
import { selectAuthenticatedUsersHousehold } from "../data/selectors/userSelectors";

const Layout = () => {
  const authUserHouseholdId = useSelector(selectAuthenticatedUsersHousehold, shallowEqual);
  const iaCode = useSelector(selectIACode, shallowEqual);

  useDispatchOnMount(initializeHouseholdAccounts, authUserHouseholdId);
  useDispatchOnMount(getHousehold, authUserHouseholdId);
  useDispatchOnMount(getPersons, authUserHouseholdId);
  useDispatchOnMount(loadTeam, iaCode);
  useDispatchOnMount(loadWorkshop, authUserHouseholdId);

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

export default Layout;
