import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FinancialInfoConfirmation, saveFinancialInfo, useFinancialInfo } from '@qwealth/kyc';
import { Box } from '@qwealth/qcore';
import { useAppDispatch } from "data/store";
import { UpdateButton } from '../common';
import EditFinancial from './EditFinancial';
import { selectAuthUserHouseholdId, selectUser } from '@qwealth/qdata';

interface Props {
  QID: string;
}

const FinancialInformation: React.FC<Props> = ({ QID }) => {
  const dispatch = useAppDispatch();

  const { email } = useSelector(selectUser);
  const householdQID = useSelector(selectAuthUserHouseholdId);

  const { financialInfo, setFinancialInfo } = useFinancialInfo(QID);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const toggleModal = () => setShowEditModal(!showEditModal);

  return (
    <Box display="flex" flexDirection="column">
      <FinancialInfoConfirmation financialInfo={financialInfo} />
      <EditFinancial
        updateFormValues={async (values) => {
          dispatch(saveFinancialInfo(QID, values, householdQID, email));
          setFinancialInfo(values);
        }}
        initialValues={financialInfo}
        shouldShowModal={showEditModal}
        onClose={toggleModal}
      />
      <UpdateButton color="green" onClick={toggleModal}>
        Update
      </UpdateButton>
    </Box>
  );
};

export default FinancialInformation;
