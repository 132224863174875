import React, { FC, ReactElement, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Form } from "react-bootstrap";
import { Box, Button, TextInput } from "@qwealth/qcore";

const isValidRegex = (regex: RegExp, password: string): boolean => {
  const matches = password.match(regex);
  return !!matches && matches.length > 0;
};

const hasUppercaseRegex = /[A-Z]/gm;
const hasLowercaseRegex = /[a-z]/gm;
const hasNumberRegex = /\d/gm;
const hasSpecialRegex = /[^A-Za-z\d]/gm;

interface Props {
  password: string;
  passwordConfirmation: string;
}

const PasswordChecklist: FC<Props> = ({ password, passwordConfirmation }): ReactElement => (
  <>
    <Form.Check
      type="checkbox"
      disabled
      checked={isValidRegex(hasUppercaseRegex, password)}
      label="Has uppercase"
    />
    <Form.Check
      type="checkbox"
      disabled
      checked={isValidRegex(hasLowercaseRegex, password)}
      label="Has lowercase"
    />
    <Form.Check
      type="checkbox"
      disabled
      checked={isValidRegex(hasNumberRegex, password)}
      label="Has number"
    />
    <Form.Check
      type="checkbox"
      disabled
      checked={isValidRegex(hasSpecialRegex, password)}
      label="Has special character"
    />
    <Form.Check
      type="checkbox"
      disabled
      checked={password.length >= 8}
      label="Password length > 7"
    />
    <Form.Check
      type="checkbox"
      disabled
      checked={password === passwordConfirmation}
      label="Password fields match"
    />
  </>
);

const ForgotPassword = () => {
  const [username, setUsername] = useState<string>('');
  const [tempPassword, setTempPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [inProgress, setInProgress] = useState(false);
  const [message, setMessage] = useState<string>('');

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams) {
      setUsername(decodeURIComponent(searchParams.get('user_name') ?? ''));
      setCode(searchParams.get('confirmation_code') ?? '');
      setTempPassword(decodeURIComponent(searchParams.get('temp_password') ?? ''));
    }
  }, [searchParams]);

  const resetHandler = async () => {
    setInProgress(true);

    const errorHandler = (err): void => {
      console.error(err);
      setMessage('Sorry, an error has occurred. Please try again.');
      setTimeout(() => {
        navigate('/');
      }, 10000)
    };

    if (tempPassword) {
      console.log({ tempPassword })
      console.log('temp psw flow')
      Auth.signIn(username, tempPassword)
        .then((user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            Auth.completeNewPassword(user, newPassword)
              .then(() => {
                setMessage('Your password was successfully set. Welcome to QConnect!');
                setTimeout(() => {
                  navigate('/')
                }, 10000);
              })
              .catch(errorHandler);
          }
        });
    } else if (code) {
      await Auth.forgotPasswordSubmit(username, code, newPassword)
        .then(() => {
          setMessage('Your password was successfully changed! Please login with your new password on the next page.');
          setTimeout(() => {
            navigate('/')
          }, 10000);
        })
        .catch(errorHandler);
    } else {
      await Auth.forgotPassword(username)
        .then(() => setMessage('Check your email for instructions on how to reset your password.'))
        .catch(errorHandler);
    }
  };

  const isValid = username
    && username.includes('@')
    && (!code || (newPassword && newPassword === newPasswordConfirmation))
    && !inProgress;

  return (
    <Box display="flex" justifyContent="center">
      <Box display="grid" gap="default" mt="largest">
        <img src="/images/qconnect-qw-logo.png" alt="QW Logo" />
        <br />
        {message
          ? <p>{message}</p>
          : (
            <>
              <Box textStyle="formLabel">Email*</Box>
              <TextInput
                value={username}
                onChange={e => setUsername(e.target.value)}
                disabled={inProgress}
              />
              {(code || tempPassword) && (
                <>
                  <Box textStyle="formLabel">New Password*</Box>
                  <TextInput
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    disabled={inProgress}
                    type="password"
                  />
                  <Box textStyle="formLabel">Confirm New Password*</Box>
                  <TextInput
                    value={newPasswordConfirmation}
                    onChange={e => setNewPasswordConfirmation(e.target.value)}
                    disabled={inProgress}
                    type="password"
                  />
                  <PasswordChecklist password={newPassword} passwordConfirmation={newPasswordConfirmation} />
                </>
              )}
              <br />
              <Button onClick={resetHandler} disabled={!isValid}>Submit</Button>
            </>
          )}
      </Box>
    </Box>
  );
}

export default ForgotPassword;
