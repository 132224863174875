import { Reducer } from 'redux';
import {reducerFactory, SET_PERSONS_TYPE, SetPersonsAction} from '@qwealth/qdata';

import { Household } from '../data-layer/household';
import {
  SET_HOUSEHOLD_TYPE,
  SetHouseholdAction,
} from '../actions/householdAction';
import {PersonWithRRSP} from "../data-layer/person";

export interface HouseholdReduxState extends Household {
  members?: Array<PersonWithRRSP>
}

const setHousehold: Reducer<HouseholdReduxState, SetHouseholdAction> = (state, { household }) => ({
  ...state,
  ...household,
});

const DEFAULT_HOUSEHOLD = {
  city: '',
  country: '',
  fullAddress: '',
  id: '',
  name: '',
  postalCode: '',
  province: '',
  type: '',
  unitNumber: '',
};

const setPersons: Reducer<HouseholdReduxState, SetPersonsAction> = (state, { persons }) => ({
  ...DEFAULT_HOUSEHOLD,
  ...state,
  members: persons
});

export default reducerFactory({
  [SET_HOUSEHOLD_TYPE]: setHousehold,
  [SET_PERSONS_TYPE]: setPersons,
});
