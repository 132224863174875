import { fetchPartner } from '@qwealth/qdata';
import { errorHandler } from '../../services/axiosService';

export const SET_PARTNER = 'SET_PARTNER';

export const putPartner = (partner) => ({ type: SET_PARTNER, partner });

export const getPartner = (partnerId) => {
  return async (dispatch) => {
    try {
      const partner = await fetchPartner(partnerId);
      dispatch(putPartner(partner));
    } catch (e) {
      errorHandler(dispatch)(e);
    }
  };
};
