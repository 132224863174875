import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@qwealth/qcore';
import { selectAuthUserHouseholdId, selectUser } from '@qwealth/qdata';
import { useAppDispatch } from "data/store";
import { RegulatoryInfoConfirmation, saveRegulatoryInfo, useRegulatoryInfo } from '@qwealth/kyc';
import { UpdateButton } from '../common';
import EditRegulatory from './EditRegulatory';

interface Props {
  QID: string;
}

const RegulatoryInformation: FC<Props> = ({ QID }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { email } = useSelector(selectUser);
  const householdQID = useSelector(selectAuthUserHouseholdId);
  const { regulatoryInfo, setRegulatoryInfo } = useRegulatoryInfo(QID);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const toggleModal = () => setShowEditModal(!showEditModal);

  return (
    <Box display="flex" flexDirection="column">
      <RegulatoryInfoConfirmation regulatoryInfo={regulatoryInfo} context="Entity" />
      <EditRegulatory
        updateFormValues={async (values) => {
          await dispatch(saveRegulatoryInfo(QID, values, householdQID, email));
          setRegulatoryInfo(values);
        }}
        initialValues={regulatoryInfo}
        shouldShowModal={showEditModal}
        onClose={toggleModal}
      />
      <UpdateButton color="green" onClick={toggleModal}>
        Update
      </UpdateButton>
    </Box>
  );
};

export default RegulatoryInformation;
