import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { ProgressBar } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Box, Button, Loader } from '@qwealth/qcore';

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    resetOnSubmit: PropTypes.bool
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = { page: 0, loading: false };
  }

  next = (values) => {
    this.setState((state) => ({
      page: Math.min(state.page + 1, React.Children.toArray(this.props.children).length - 1)
    }));
    const { setValues } = this.props;
    setValues && setValues(values);
  };

  previous = () => {
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0)
    }));
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */
  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values) => {
    const { onSubmit, children, resetOnSubmit } = this.props;
    const pageState = React.Children.toArray(children);
    const isLastPage = pageState.length - 1 === this.state.page;
    if (isLastPage) {
      if (resetOnSubmit) {
        this.setState({ loading: true });
      }
      onSubmit(values);
      if (resetOnSubmit) {
        // TODO: remove this hack later...
        setTimeout(() => {
          this.setState({ page: 0, loading: false });
        }, 2000);
      }
    } else {
      return this.next(values);
    }
  };

  render() {
    const { children, hideProgress, initialValues } = this.props;
    const { page, loading } = this.state;

    const pageState = React.Children.toArray(children);
    const activePage = React.cloneElement(pageState[page], { onSubmit: this.next });

    const isLastPage = pageState.length - 1 === page;

    if (loading) {
      return <Loader />;
    }

    return (
      <Form
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {!hideProgress && (
              <Box
                display="flex"
                justifyContent="center"
                fontSize="sectionTitle"
                fontWeight="bold"
                mb="small"
              >
                {`${page + 1} of ${pageState.length}`}
              </Box>
            )}
            <Box marginBottom="large">
              {!hideProgress && (
                <ProgressBar variant="success" min={0} now={page} max={pageState.length - 1} />
              )}
            </Box>
            <Box
              display="flex"
              justifyContent={page === 0 ? 'flex-end' : 'space-between'}
              mb="large"
            >
              {page > 0 && (
                <Button variant="icon" onClick={this.previous}>
                  <BsChevronLeft />
                </Button>
              )}
              {!isLastPage && (
                <Button variant="icon" type="submit">
                  <BsChevronRight />
                </Button>
              )}
            </Box>
            {activePage}
            <Box display="flex" justifyContent="center" mt="large">
              {isLastPage && (
                <Button type="submit" disabled={submitting}>
                  Save
                </Button>
              )}
            </Box>
          </form>
        )}
      </Form>
    );
  }
}
