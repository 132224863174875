import React, { useState } from 'react';
import { ToggleButton } from 'react-bootstrap';
import { Field, useFormState } from 'react-final-form';

import { Box, TextAreaInput, styled } from '@qwealth/qcore';

import Wizard from 'components/common/Wizard';
import Error from 'components/common/Error';
import { FIELD_SUBSCRIPTION, required } from 'components/common/wizardHelper';

const StyledToggleButton = styled(ToggleButton)`
  input {
    margin-right: 10px;
  }
`;

interface Props {
  question: string;
  index: number;
  onSubmit?: (values: Record<string, string>) => void;
}

const QuestionPage: React.FC<Props> = ({ question, index, onSubmit }) => {
  const { values } = useFormState();
  const fieldName = `question${index + 1}`;
  const value = values[fieldName];

  const [showDetail, setShowDetail] = useState(value !== undefined && value !== 'no');
  return (
    <Wizard.Page>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        justifyItems="center"
        gap="large"
        textAlign="center"
        paddingX="large"
      >
        <Box textStyle="formLabel" fontSize="pageTitle">
          {question}
        </Box>
        <Field name={fieldName} validate={required} subscription={FIELD_SUBSCRIPTION}>
          {({ input }) => (
            <>
              <Error name={fieldName} />
              <Box display="flex" justifyContent="center" gap="large">
                <StyledToggleButton
                  name={fieldName}
                  id="toggle-yes"
                  type="checkbox"
                  variant="primary"
                  checked={showDetail}
                  value={1}
                  style={{ backgroundColor: '#93315d' }}
                  onChange={() => {
                    input.onChange('');
                    setShowDetail(true);
                  }}
                >
                  Yes
                </StyledToggleButton>
                <StyledToggleButton
                  name={fieldName}
                  id="toggle-no"
                  type="checkbox"
                  variant="secondary"
                  checked={input.value === 'no'}
                  value={0}
                  style={{ backgroundColor: '#5e1442' }}
                  onChange={() => {
                    input.onChange('no');
                    setShowDetail(false);
                    onSubmit!({
                      ...values,
                      [fieldName]: 'no'
                    });
                  }}
                >
                  No
                </StyledToggleButton>
              </Box>
            </>
          )}
        </Field>
        {showDetail && (
          <Box>
            <Error name={fieldName} />
            <Field validate={required} name={fieldName}>
              {({ input }) => (
                <TextAreaInput
                  {...input}
                  rows={10}
                  width="100%"
                  placeholder="Please provide details*"
                />
              )}
            </Field>
          </Box>
        )}
      </Box>
    </Wizard.Page>
  );
};

export default QuestionPage;
