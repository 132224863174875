import { PUT_TIMELINE } from '../actions/timeline';

export default function timelineReducer(state = [], action) {
  switch (action.type) {
    case PUT_TIMELINE: {
      const { timeline } = action;
      if (timeline?.length >= 2) {
        return [timeline[0], timeline[timeline.length - 1]];
      } else return timeline;
    }
    default:
      return state;
  }
}
