import React from 'react';
import { Modal } from 'react-bootstrap';
import { Box, Button } from '@qwealth/qcore';
import { IdentityVerification } from '@qwealth/kyc';

interface Props {
  shouldShowModal: boolean;
  onClose: () => void;
  updateFormValues: (values: any) => Promise<void>;
  QID: string;
}
const EditGovernment: React.FC<Props> = ({ shouldShowModal, onClose, updateFormValues, QID }) => {
  const submitHandler = async (values) => {
    await updateFormValues(values);
    onClose();
  };
  return (
    <Modal onHide={onClose} show={shouldShowModal} size="xl">
      <Modal.Header closeButton>
        <Box textStyle="sectionTitle">Edit Government Information</Box>
      </Modal.Header>
      <Modal.Body>
        <IdentityVerification QID={QID} onSubmit={submitHandler} editing />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGovernment;
