import { SET_PARTNER } from '../actions/partner';

export default function partnerReducer(state = {}, action) {
  switch (action.type) {
    case SET_PARTNER: {
      const { partner } = action;
      return partner;
    }
    default:
      return state;
  }
}
