import { Box, styled } from '@qwealth/qcore';

export const SplashContainer = styled(Box)`
  display: flex;
  max-width: 1536px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: calc(100% - 86px);
  font-family: TeX-Gyre-Adventor;
  height: auto;

  img {
    width: 160px;
  }

  button {
    font-size: 16px;
    padding: 15px 30px;
    text-transform: uppercase;
    border-radius: 12px;
    font-weight: 600;
    background-color: #93315d;
    font-family: Raleway;
  }

  @media screen and (max-width: 768px) {
    padding: 20px;

    .title {
      font-size: 28px;
    }

    .box-wrapper {
      flex-direction: column;

      & > div {
        margin: 10px 0;
      }
    }
  }
`;

export const StyledBox = styled(Box)`
  border-radius: 20px;
  padding: 20px 30px;
  display: flex;
  flex: 1;
  margin: 0 1%;
  max-width: 540px;

  .number {
    font-size: 100px;
    color: #a6607c;
    margin-right: 30px;
    font-weight: bold;
    padding-bottom: 10px;
    line-height: 130px;
  }

  @media screen and (max-width: 768px) {
    br {
      display: none;
    }
  }
`;

export const GreenSpan = styled.span`
  color: green;
`;

export const RedSpan = styled.span`
  color: red;
`;
