import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Box } from '@qwealth/qcore';
import { selectUser } from '@qwealth/qdata';

import { SplashContainer } from 'components/common/SplashStyling';

const Confirmation: React.FC = () => {
  const navigate = useNavigate();
  const firstName = useSelector(selectUser)?.firstName ?? '';

  return (
    <SplashContainer>
      <img alt="KYC Attestation" src="/images/check.png" />
      <Box as="div" fontSize="pageTitle" color="primaryLight.1">
        Thank you, <b>{firstName}.</b>
        <br />
        We will be in touch.
      </Box>
      <Box as="div" fontSize="large" mt="large" textAlign="center" mb="largest">
        If your Portfolio Manager has any questions or needs further details,
        <br />a member of our team will be in touch.
      </Box>

      <Button
        color="primary"
        onClick={() => {
          navigate({ pathname: '/' });
        }}
      >
        Go to my QConnect Portal
      </Button>
    </SplashContainer>
  );
};

export default Confirmation;
