import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Box, Button, styled } from '@qwealth/qcore';
import Avatar from 'components/common/Avatar';
import { RootState } from "data/store";

const StyledPopup = styled(Box)`
  position: absolute;
  z-index: 997;
  right: 0;
  top: 50px;
  opacity: 0;
  transform: translate(0, -10%);
  display: none;

  &:after {
    content: '';
    position: absolute;
    top: -8px;
    right: 12px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }

  &.show {
    display: block;
    animation: fadein 0.25s linear forwards;
  }

  @keyframes fadein {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export default function ProfileAvatar() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user);
  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const initials = `${firstName?.[0] || ''} ${lastName?.[0] || ''}`;

  const handleClick = () => {
    setShowPopup(false);
    navigate({ pathname: '/profile' });
  };

  const logOutHandler = async () => {
    await Auth.signOut();
    navigate(0);
  };

  return (
    <Box style={{ position: 'relative' }}>
      <Avatar
        initials={initials}
        style={{ cursor: 'pointer' }}
        onClick={() => setShowPopup(!showPopup)}
      />
      <StyledPopup
        className={showPopup ? 'show' : undefined}
        boxShadow="layout"
        height="auto"
        width="auto"
        p="default"
        backgroundColor="white"
        borderRadius="default"
      >
        <Button variant="outline" mb="default" onClick={handleClick}>
          PROFILE
        </Button>
        <Button color="primary" onClick={logOutHandler}>
          LOG OUT
        </Button>
      </StyledPopup>
    </Box>
  );
}
