import { Button, Heading, useTheme, Image, Text, Theme, View } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";

export const CognitoComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <Heading padding={`${tokens.space.xl} 0 ${tokens.space.xl} 0`} level={3} textAlign="center">
        <Image src="/images/qconnect-qw-logo.png" alt="QW Logo" />
        <Text marginTop="medium">Welcome Back!</Text>
        <Text marginTop="small" fontSize="small">Sign into your account here:</Text>
      </Heading>
    );
  },
  SignIn: {
    Footer() {
      const navigate = useNavigate();
      return (
        <View textAlign="center" marginBottom="large">
          <Button onClick={() => navigate({ pathname: 'forgot-password' })}>
            Forgot password?
          </Button>
        </View>
      );
    }
  }
};

export const formFields = {
  signIn: {
    username: {
      label: 'Email Address',
      placeholder: 'Enter your Email',
    },
  },
  resetPassword: {
    username: {
      label: 'Email Address',
      placeholder: 'Enter your Email',
    },
  },
};

const primaryColor = '#5e1541';
const hoverColor = '#93345d';
const hoverBackground = 'rgba(94, 21, 65, 0.1)';
export const theme: Theme = {
  name: 'QConnect Auth Theme',
  tokens: {
    colors: {
      font: {
        interactive: {
          value: primaryColor,
        },
        hover: { value: primaryColor },
        active: { value: primaryColor },
        focus: { value: primaryColor },
      },
    },
    fonts: {
      default: {
        variable: { value: 'Raleway' },
        static: { value: 'Raleway' },
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: primaryColor },
          _hover: { backgroundColor: { value: hoverColor } },
          _active: { backgroundColor: { value: primaryColor } },
          _focus: { backgroundColor: { value: primaryColor } },
        },
        link: {
          backgroundColor: { value: '#ffffff' },
          _hover: { backgroundColor: { value: hoverBackground } },
          _active: { backgroundColor: { value: hoverBackground } },
          _focus: { backgroundColor: { value: hoverBackground } },
        },
      },
    },
  },
};
