import {
  PUT_ALL_ACTIVITIES,
  SET_ACTIVITIES_LOADING,
} from '../actions/activities';

export default function activitiesReducer(
  state = {
    allActivities: [],
    isLoading: false,
    isInitialized: false,
  },
  action,
) {
  switch (action.type) {
    case PUT_ALL_ACTIVITIES:
      return { ...state, allActivities: action.allActivities, isInitialized: true };
    case SET_ACTIVITIES_LOADING:
      return { ...state, isLoading: action.loading };
    default:
      return state;
  }
}
