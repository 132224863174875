import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@qwealth/qcore';
import { selectAuthUserHouseholdId, selectUser } from '@qwealth/qdata';
import {
    DemographicForm,
    saveTrustedContactInfo,
    TrustedContactInfoConfirmation,
    useTrustedContactInfo
} from '@qwealth/kyc';
import { UpdateButton } from '../common';
import EditTrusted from './EditTrusted';
import { useAppDispatch } from "data/store";

interface Props {
  QID: string;
  demographicInfo: DemographicForm;
  setDemographicInfo: Dispatch<SetStateAction<DemographicForm>>;
}

const TrustedContactInformation: React.FC<Props> = ({ QID, demographicInfo, setDemographicInfo }) => {
  const { email } = useSelector(selectUser);
  const householdQID = useSelector(selectAuthUserHouseholdId);

  const dispatch = useAppDispatch();
  const { trustedContactInfo, setTrustedContactInfo } = useTrustedContactInfo(QID, demographicInfo);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const toggleModal = () => setShowEditModal(!showEditModal);

  return (
    <Box display="flex" flexDirection="column">
      <TrustedContactInfoConfirmation trustedContactInfo={trustedContactInfo} demographicInfo={demographicInfo} />
      <EditTrusted
        updateFormValues={async (values) => {
          await dispatch(saveTrustedContactInfo(QID, values, householdQID, email));
          const { hasTrustedContact } = values;
          setDemographicInfo(prevState => ({ ...prevState, hasTrustedContact }))
          setTrustedContactInfo(values);
        }}
        initialValues={trustedContactInfo}
        shouldShowModal={showEditModal}
        onClose={toggleModal}
      />
      <UpdateButton color="green" onClick={toggleModal}>
        Update
      </UpdateButton>
    </Box>
  );
};

export default TrustedContactInformation;
