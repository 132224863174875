import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@qwealth/qcore';
import { selectAuthUserHouseholdId, selectUser } from '@qwealth/qdata';
import { GovernmentIdConfirmation, saveGovernmentID, useGovernmentInfo } from '@qwealth/kyc';
import { UpdateButton } from '../common';
import EditGovernment from './EditGovernment';
import { useAppDispatch } from "data/store";

interface Props {
  QID: string;
}

const GovernmentInformation: React.FC<Props> = ({ QID }) => {
  const { email } = useSelector(selectUser);
  const householdQID = useSelector(selectAuthUserHouseholdId);

  const dispatch = useAppDispatch();
  const { governmentInfo, setGovernmentInfo } = useGovernmentInfo(QID);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const toggleModal = () => setShowEditModal(!showEditModal);
  return (
    <Box display="flex" flexDirection="column">
      <GovernmentIdConfirmation governmentInfo={governmentInfo} />
      <EditGovernment
        updateFormValues={async (values) => {
          await dispatch(saveGovernmentID(QID, values, householdQID, email));
          setGovernmentInfo(values);
        }}
        shouldShowModal={showEditModal}
        onClose={toggleModal}
        QID={QID}
      />
      <UpdateButton color="green" onClick={toggleModal}>
        Update
      </UpdateButton>
    </Box>
  );
};

export default GovernmentInformation;
