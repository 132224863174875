import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@qwealth/qcore';
import { selectAuthUserHouseholdId, selectUser } from '@qwealth/qdata';
import { useDemographicInfo, saveDemographicInfo, EmploymentInfoConfirmation } from '@qwealth/kyc';
import { useAppDispatch } from "data/store";
import DemographicInformation from './DemographicInformation';
import AddressesInformation from './AddressesInformation';
import RegulatoryInformation from './RegulatoryInformation';
import GovernmentInformation from './GovernmentInformation';
import FinancialInformation from './FinancialInformation';
import TrustedContactInformation from './TrustedContactInformation';

const KYCFormComponents: React.FC = () => {
  const dispatch = useAppDispatch();
  const { QID, email } = useSelector(selectUser);
  const householdQID = useSelector(selectAuthUserHouseholdId) ?? '';

  const { demographicInfo, setDemographicInfo } = useDemographicInfo(QID);

  return (
    <Box paddingTop="largest">
      <DemographicInformation
        demographicInfo={demographicInfo}
        updateFormValues={async (values) => {
          await dispatch(saveDemographicInfo(QID, values, householdQID, email));
          setDemographicInfo(values);
        }}
      />
      <AddressesInformation QID={QID} />
      <RegulatoryInformation QID={QID} />
      <EmploymentInfoConfirmation contactQid={QID} showActions />
      <GovernmentInformation QID={QID} />
      <FinancialInformation QID={QID} />
      <TrustedContactInformation
        QID={QID}
        demographicInfo={demographicInfo}
        setDemographicInfo={setDemographicInfo}
      />
    </Box>
  );
};
export default KYCFormComponents;
