import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@qwealth/qcore';
import {
  QContext,
  selectAuthUserHouseholdId,
  selectUser,
  AnnualReview,
  AnnualReviewQuestions,
  fetchContactKyc,
  RawKYC
} from '@qwealth/qdata';

import Wizard from 'components/common/Wizard';
import { addAnnualReview } from 'data/actions/annualReviewActions';
import QuestionPage from './QuestionPage';
import { useAppDispatch } from "data/store";

const defaultAnnualReviewResponses: Record<string, undefined> = AnnualReviewQuestions.reduce(
  (responses, _, index) => ({ ...responses, [`question${index + 1}`]: undefined }),
  {}
);

const RtqReview: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [formValues, setFormValues] = useState(defaultAnnualReviewResponses);
  const [kycId, setKycId] = useState<number>();
  const { QID, email } = useSelector(selectUser);
  const householdQID = useSelector(selectAuthUserHouseholdId) ?? '';

  useEffect(() => {
    const fetchKycId = async () => {
      const id = await fetchContactKyc(QID)
        .then(kyc => (kyc as RawKYC).id ?? 0)
        .catch(err => {
          QContext.getErrorHandler()(dispatch, 'Could not send KYC attestation')(err);
          return 0;
        });

      if (id !== 0) {
        setKycId(id);
      }
    };
    fetchKycId();
  }, [QID, dispatch]);

  const submitHandler = async (answers: Array<string>) => {
    const payload: Partial<AnnualReview> = {
      id: params['id'] ? parseInt(params['id']) : undefined,
      kycID: kycId!,
      QID
    };
    Object.keys(answers).forEach(
      (key) => (payload[key] = answers[key] !== 'no' ? answers[key] : '')
    );

    await dispatch(addAnnualReview(payload as AnnualReview, QID, householdQID, email));
    navigate({ pathname: `/annual-review/${params['id']}/confirmation` });
  };

  return (
    <Box padding="large">
      <Wizard initialValues={formValues} setValues={setFormValues} onSubmit={submitHandler}>
        {AnnualReviewQuestions.map((question, index) => (
          <QuestionPage
            key={`Annual Review Question - ${index + 1}`}
            question={question}
            index={index}
          />
        ))}
      </Wizard>
    </Box>
  );
};

export default RtqReview;
