import { QContext, AnnualReview, saveAnnualReview } from '@qwealth/qdata';

import { Dispatch } from 'redux';

export const addAnnualReview =
  (payload: AnnualReview, QID: string, householdQID: string, email: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await saveAnnualReview(payload, QID, householdQID, email);
    } catch (error) {
      QContext.getErrorHandler()(dispatch, 'Could not submit Annual Review Questionnaire')(error);
    }
  };
