import { fetchUserByUsername, getAuthenticatedUsersHouseholdId } from '@qwealth/qdata';
import { setAuthUser, setUserHouseholdId } from './auth';
import { AppThunkDispatch } from "../store";

export const getAuthenticatedUser = (email: string) => async (dispatch: AppThunkDispatch) => {
  const user = await fetchUserByUsername(email, true);
  dispatch(setAuthUser(user[0]));
};

export const getUserHouseholdId = (user) => async (dispatch: AppThunkDispatch) => {
  if (user.QID && user.QID !== '') {
    const household = await getAuthenticatedUsersHouseholdId(user);
    // @ts-ignore TODO
    dispatch(setUserHouseholdId(household.householdQID));
  } else {
    return false;
  }
};
