import React from 'react';
import { Box, Card } from '@qwealth/qcore';

export default function ExternalApps() {
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(2, minmax(25px, min-content))"
      justifyContent="center"
      gap="large"
      p="default"
    >
      <Card>
        <img
          alt="Digit" src="/images/Digit.png"
          onClick={() => window.location.assign('https://qwealth.d1g1t.com/')}
        />
      </Card>
      <Card>
        <img
          alt="NBIN" src="/images/NBIN.png"
          onClick={() => window.location.assign('https://myportfolioplus.ca/nbin/login')}
        />
      </Card>
    </Box>
  );
}
