import { path } from 'ramda';
import {
  createApi,
  parseResponse,
  withAuth,
  IHouseholdMemberDto,
  fetchLegalEntity,
  ILegalEntityDTO
} from '@qwealth/qdata';

import {apiUrl} from './constants';
import {ApiResponse} from './types';

export type HouseholdProfile = {
  employmentStatus: string;
  firstName: string;
  isEmployed: boolean;
  lastName: string;
  profileId: string;
  province: string;
  // rrsps: Array<RRSP>;
};

export type Household = {
  city: string;
  country: string;
  fullAddress: string;
  id: string | number;
  name: string;
  postalCode: string;
  province: string;
  type: string;
  unitNumber: string;
};

type HouseholdToPersonsRelationships = {
  data: Array<IHouseholdMemberDto>;
};

export const householdApi = withAuth(createApi(`${apiUrl}/households`));

const parseData: <T>(input: { data: [T] }) => T | undefined = path(['data', 0]);

export const fetchHousehold = async (householdId: string | number): Promise<Household> => {
  const response = await householdApi(householdId);
  const data = await parseResponse<ApiResponse<Household>>(response);
  // @ts-ignore
  return parseData(data);
};

export const fetchHouseholdMembers = async (
  householdId: string,
): Promise<Array<IHouseholdMemberDto>> => {
  const response = await householdApi(`${householdId}/members`);
  const data = await parseResponse<HouseholdToPersonsRelationships>(response);
  return data.data;
};

export const getLegalEntities = async (householdId: string): Promise<Array<ILegalEntityDTO>> => {
  const householdMembers = await fetchHouseholdMembers(householdId);
  const promises: Array<Promise<any>> = householdMembers
    .filter(({ memberType }) => ['Corporate', 'Trust'].includes(memberType))
    .map(member => {
      if (member.legalEntityQID) {
        return fetchLegalEntity(member.legalEntityQID);
      }
      return Promise.reject(new Error('Invalid legal entity QID for household' + member.householdQID));
    });

  return await Promise.all(promises);
}
