import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@qwealth/qcore';
import { selectAuthUserHouseholdId, selectUser } from '@qwealth/qdata';
import { AddressesInfoConfirmation, DemographicForm, saveAddressesInfo, useAddressInfo } from '@qwealth/kyc';
import { useAppDispatch } from "data/store";
import { UpdateButton } from '../common';
import EditAddresses from './EditAddresses';

interface Props {
  QID: string;
}

const AddressesInformation: FC<Props> = ({ QID }) => {
  const { email } = useSelector(selectUser);
  const householdQID = useSelector(selectAuthUserHouseholdId);

  const dispatch = useAppDispatch();
  const { addressesInfo, setAddressesInfo } = useAddressInfo(QID);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const toggleModal = () => setShowEditModal(!showEditModal);


  return (
    <Box display="flex" flexDirection="column">
      <AddressesInfoConfirmation addressesInfo={addressesInfo} />
      <EditAddresses
        updateFormValues={async (values) => {
          await dispatch(saveAddressesInfo(QID, values, householdQID, email));
          setAddressesInfo(values);
        }}
        initialValues={addressesInfo}
        shouldShowModal={showEditModal}
        onClose={toggleModal}
      />
      <UpdateButton color="green" onClick={toggleModal}>
        Update
      </UpdateButton>
    </Box>
  );
};

export default AddressesInformation;
