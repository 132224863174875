import { errorHandler } from '../../services/axiosService';
import { getAllTasks } from '@qwealth/qdata';

export const SET_CASE_CREATE_LOADING = 'SET_CASE_CREATE_LOADING';
export const SET_ACTIVITIES_LOADING = 'SET_ACTIVITIES_LOADING';
export const PUT_ALL_ACTIVITIES = 'PUT_ALL_ACTIVITIES';
export const PUT_ALL_CASES = 'PUT_ALL_CASES';
export const PUT_CASE_STATUS = 'PUT_CASE_STATUS';

const setActivitiesLoading = (loading) => ({ type: SET_ACTIVITIES_LOADING, loading });
const putActivities = (allActivities) => ({ type: PUT_ALL_ACTIVITIES, allActivities });

export const loadAllActivities = (householdQID) => {
  return async (dispatch) => {
    dispatch(setActivitiesLoading(true));
    try {
      const tasks = await getAllTasks({ householdQID });

      dispatch(putActivities(tasks.filter(activity => activity.isInternal === 0)));
    } catch (error) {
      errorHandler(dispatch, 'Loading activities failed')(error);
    } finally {
      dispatch(setActivitiesLoading(false));
    }
  };
};
