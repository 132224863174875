import { combineReducers } from 'redux';
import {
  accountsReducer,
  foaReducer,
  incomeSummaryReducer,
  monthOverMonthReducer,
  partnersReducer,
  personsReducer,
  qscoreReducer,
  teamReducer,
  valuesReducer,
} from '@qwealth/qdata';
import activities from './activities';
import auth from './auth';
import dataSource from './dataSource';
import household from './household';
import partner from './partner';
import timeline from './timeline';
import workshop from './workshop';

export default combineReducers({
  accounts: accountsReducer,
  activities,
  auth,
  dataSource,
  functionOfAssets: foaReducer,
  household,
  incomeSummary: incomeSummaryReducer,
  monthOverMonth: monthOverMonthReducer,
  partner,
  partners: partnersReducer,
  persons: personsReducer,
  qscore: qscoreReducer,
  team: teamReducer,
  timeline,
  values: valuesReducer,
  workshop,
});
