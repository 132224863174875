import React, { FC, ReactElement } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Modal } from 'react-bootstrap';
import { Box, Button } from '@qwealth/qcore';
import { IRegulatory } from "@qwealth/qdata";
import { RegulatoryInternalForm, regulatoryValidator } from '@qwealth/kyc';

interface Props {
  shouldShowModal: boolean;
  onClose: () => void;
  initialValues: IRegulatory;
  updateFormValues: (values: any) => Promise<void>;
}

const EditRegulatory: FC<Props> = ({
  shouldShowModal,
  onClose,
  initialValues,
  updateFormValues
}): ReactElement => {
  const submitHandler = async (values) => {
    await updateFormValues(values);
    onClose();
  };
  return (
    <Form
      validate={(values) => regulatoryValidator(values).resultClean}
      onSubmit={submitHandler}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit }) => (
        <Modal onHide={onClose} show={shouldShowModal} size="xl">
          <Modal.Header closeButton>
            <Box textStyle="sectionTitle">Edit Additional Information</Box>
          </Modal.Header>
          <Modal.Body>
            <RegulatoryInternalForm editing context="Contact" />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={handleSubmit}>
              Update
            </Button>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    />
  );
};

export default EditRegulatory;
