import { createApi, parseResponse } from '@qwealth/qdata';

export type TaxBracket = {
  rate: number;
  value: number;
};

export type DataSourceConstants = {
  employeeRate: number;
  exemption: number;
  maxRRSPContributionAmount: number;
  maxRRSPContributionPercent: number;
  qpipEmployedAmount: number;
  qpipEmployedRate: number;
  qpipMinEmploymentIncome: number;
  qpipSelfEmployedAmount: number;
  qpipSelfEmployedRate: number;
  selfEmployedRate: number;
  ympe: number;
};

export type DataSource = {
  constants: DataSourceConstants;
  federalTaxBrackets: Array<TaxBracket>;
  incomeAndTaxAdjustmentTypes: Array<string>;
  incomeSourceTypes: Array<string>;
  provinces: Array<string>;
  provincialTaxBrackets: Record<string, Array<TaxBracket>>;
  taxCredits: Array<string>;
};

export const dataSourceApi = createApi('/api-mocks/data-source.json');

export const fetchDataSource = async (): Promise<DataSource> => {
  const response = await dataSourceApi();
  const data = await parseResponse<DataSource>(response);
  return data;
};
