import { Workshop, PUT_WORKSHOP, PUT_OUTCOME_TYPE } from '@qwealth/qdata';

interface IWorkshopState {
  initializedWorkshop: boolean;
  workshop: Workshop;
}

const initialState: IWorkshopState = {
  initializedWorkshop: false,
  workshop: {
    id: 0,
    householdQID: '',
    isPrimary: 1,
    workshopPlanDetails: [],
    workshopToValueOrFOA: [],
    outcomes: {},
  },
};

export default (state = initialState, action: any): IWorkshopState => {
  switch (action.type) {
    case PUT_WORKSHOP: {
      return { ...state, initializedWorkshop: true, workshop: action.payload };
    }
    case PUT_OUTCOME_TYPE: {
      return {
        ...state,
        workshop: {
          ...state.workshop,
          outcomes: { ...state.workshop.outcomes, ...action.payload },
        },
      };
    }
    default:
      return state;
  }
};
